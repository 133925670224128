.QueryPage {
  text-align: center;
  align-content: center;
  max-width: 1920px;
  margin: 0 auto;
}

.Label {
  font-size: 18px;
  margin-top: 22px;
  color: #C2C2C2;
  text-align: center;
  letter-spacing: 1px;
}

.Img {
  width: 164px;
}

.ExpressItemLayout {
  text-align: center;
  align-content: center;
  margin-top: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ExpressFa {
  width: 100%;
  padding-right: 108px;
  padding-left: 108px;
}