
.news-desc {
  font-size: 14px;
  color: #C2C2C2;
  text-align: start;
  width: 1000px;
}

.news-detail-desc {
  font-size: 18px;
  color: #C2C2C2;
  text-align: start;
  padding: 32px;
  max-width: 1000px;
  line-height: 40px;
  overflow: scroll;
}

::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
  border-radius: 999px;
  border: 5px solid transparent;
}

::-webkit-scrollbar-track {
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0) inset;
}

::-webkit-scrollbar-thumb {
  min-height: 20px;
  background-clip: content-box;
  box-shadow: 0 0 0 5px rgba(0, 0, 0, 0) inset;
}

::-webkit-scrollbar-corner {
  background: transparent;
}