.TitleLabel {
  font-size: 50px;
  font-weight: 500;
  color: #C2C2C2;
  letter-spacing: 5px;
  margin-left: 148px;
  margin-bottom: 32px;
  margin-top: 140px;
}

.ContentLabel {
  font-size: 20px;
  color: #C2C2C2;
  line-height: 40px;
  text-align: start;
  max-width: 600px;
  letter-spacing: 1px;
  margin-left: 148px;
  margin-top: 16px;
}

.HomeImg {
  width: 395px;
  margin-right: 187px;
  margin-left: 204px;
  height: 384px;
}

.Btn {
  margin-left: 148px;
  margin-top: 52px;
}

.ButtonItem {
  background: #04887D;
  border-radius: 2px;
  color: white;
  border: #04887D;
  margin-right: 19px;
  font-size: 16px;
  padding-left: 22px;
  padding-right: 22px;
  height: 42px;
}