.Desc {
  font-size: 14px;
  color: #F0F0F0;
  letter-spacing: 1px;
  background-color: transparent;
  border: none;
  padding: 0 18px;
  margin: 0;
}
.FooterTitle{
  font-size: 14px;
  color: #F0F0F0;
  padding-left: 18px;
  letter-spacing: 1px;
  background-color: transparent;
  border: none;
  margin: 0;
}
.Record {
  font-size: 12px;
  color: #F0F0F0;
  margin-left: 21px;
  margin-right: 21px;
}

.FooterView {
  height: 100%;
  text-align: center;
  align-content: center;
}
.Desc:hover, .Desc:focus{
  color: #04887D;
  background: transparent;
  border-color: transparent;
}