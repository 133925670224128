.Logo {
  height: 26px;
  margin-left: 30px;
}

.Layout {
  height: 100vh;
  /*理解为屏幕高度的100%
 1.vw：1vw等于视口宽度的1%。
 2.vh：1vh等于视口高度的1%。
 3.vmin：选取vw和vh中最小的那个。
 4.vmax：选取vw和vh中最大的那个。*/
}

.HeadLayout {
  background-color: #393D4A;
  padding: 0;
  height: 60px;
  margin: 0;
  line-height: 60px
}

.ContentLayout {
  background-color: #161616;
  width: 100%;
  text-align: center;
  align-content: center;
}

.BottomLayout {
  background-color: #393D4A;
  height: 112px;
  width: 100%;
  padding: 0;
}

.Menu {
  background-color: transparent;
  margin-left: 21px;
  width: 100%;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: white;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 2px solid white;
}

.ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu {
  position: relative;
  display: inline-block;
  vertical-align: bottom;
}

.Item {
  margin: 0 !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  color: #C2C2C2;
  font-size: 15px;
  height: 60px;
  line-height: 60px;
  border-bottom: 0
}

.ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu {
  position: relative;
  display: inline-block;
  vertical-align: bottom;
}